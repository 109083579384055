import { SaveSpacesCache, Space } from "../../business";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { SpaceResponse, TableGridResponse } from "../ResponseModel";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  spaces = "spaces",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 *
 * @returns
 */
export const GetAllSpaces = async () => {
  //>> Composición de URL
  const url = API_ENDPOINT + RESOURCES.spaces;

  //>> Respuesta
  type Response = FetchResponseType<Space[]>;
  let DefaultResponse: Space[];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      SaveSpacesCache(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetSpace = async (space_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.spaces;
  const url = API_ENDPOINT + resource + "/" + space_id;

  //>> Respuesta
  type Response = FetchResponseType<SpaceResponse>;
  let DefaultResponse: SpaceResponse;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");

      return res;
    })
    .catch((error) => {
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @returns
 */
export const CreateSpace = async () => {
  //>> Composición de URL
  const resource = RESOURCES.spaces;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Space[]>;
  let DefaultResponse: Space[];

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({}),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED)
        throw new Error("No se ha podido conectar con el servidor");
      SaveSpacesCache(res.response);
      return res;
    })
    .catch((error) => {
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param space_data
 * @param tables
 * @returns
 */
export const UpdateSpace = async (
  space_data: Space,
  tables: TableGridResponse[]
) => {
  //>> Composición de URL
  const resource = RESOURCES.spaces;
  const url = API_ENDPOINT + resource + "/" + space_data.id;

  //>> Respuesta
  type Response = FetchResponseType<Space[]>;
  let DefaultResponse: Space[];

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...space_data,
      tables: tables,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      SaveSpacesCache(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteSpace = async (space_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.spaces;
  const url = API_ENDPOINT + resource + "/" + space_id;

  //>> Respuesta
  type Response = FetchResponseType<Space[]>;
  let DefaultResponse: Space[];

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      SaveSpacesCache(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
