import { MenuInCart, MenuProduct, ProductInCart } from "./BusinessModel";
import { CategoryAttributes } from "./DataModel";
import { P, ReadParameter } from "./ParametersController";

const IVA = 10;

export function PriceFormatter(price: string | number) {
  if (price == undefined) return "-";
  return parseFloat(price.toString()).toFixed(2).replace(".", ",") + " €";
}

export function FormatCurrency(v: string | number | undefined) {
  if (v === undefined) return "---";

  return parseFloat((v + "").replaceAll(",", "")).toLocaleString("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2, // Especifica el número mínimo de decimales
    maximumFractionDigits: 2, // Especifica el número máximo de decimales
  });
}

/**
 * Returns total cart price
 * @param selectedProducts
 * @param selectedMenus
 * @param orderType
 * @returns
 */
export function CalcTotalCartPrice(
  selectedProducts: ProductInCart[],
  selectedMenus: MenuInCart[],
  orderType: string
) {
  let total = 0;

  const supplement_delivery = ReadParameter(P.supplement_delivery);
  if (orderType === "delivery" && supplement_delivery != "") {
    total += parseFloat(supplement_delivery);
  }

  if (selectedProducts.length === 0 && selectedMenus.length === 0)
    return PriceFormatter(total);

  // Recorrer productos normales
  selectedProducts.forEach((item) => {
    total += item.price * item.amount;

    if (!item.edited_price)
      item.addons?.forEach((addon) => {
        if (addon.add) total += parseFloat(addon.price + "") * item.amount;
      });
  });

  // Recorrer menús
  selectedMenus.forEach((item) => {
    total += parseFloat(item.menu_price + "");

    if (!item.edited_price)
      item.products?.forEach((cp) => {
        if (cp.supplement > 0) total += cp.supplement * cp.amount;
        if (cp.addons)
          cp.addons.forEach((addon) => {
            if (addon.add && addon.pay_in_menu)
              total += parseFloat(addon.price + "") * cp.amount;
          });
      });
  });

  return PriceFormatter(total);
}


/**
 * Add product to cart
 * @param productsInCart
 * @param product
 * @returns
 */
export function AddProductToCart(
  productsInCart: ProductInCart[],
  product: ProductInCart
): ProductInCart[] {
  let auxArray: ProductInCart[] = [...productsInCart];
  const productIndex: number = auxArray.findIndex(
    (p, i) =>
      p.id === product.id &&
      p.observation === product.observation &&
      JSON.stringify(p.addons) === JSON.stringify(product.addons)
  );

  if (productIndex > -1)
    auxArray[productIndex] = {
      uid: product.uid,
      id: product.id,
      amount: productsInCart[productIndex].amount + product.amount,
      name: product.name || "",
      observation: product.observation,
      price: product.price,
      addons: product.addons,
      delay: 0,
      can_delay: product.can_delay || false,
      edited_price: product.edited_price || false,
      category_id: product.category_id,
      hide_attributes: product.hide_attributes,
    };
  else
    auxArray.push({
      uid: product.uid,
      id: product.id,
      amount: product.amount,
      name: product.name || "",
      observation: product.observation,
      price: product.price,
      addons: product.addons,
      delay: 0,
      can_delay: product.can_delay || false,
      edited_price: product.edited_price || false,
      hide_attributes: product.hide_attributes,
      category_id: product.category_id,
    });

  return auxArray;
}

/// Remove Product from Cart
export function RemoveProductFromCart(
  productsInCart: ProductInCart[],
  product: ProductInCart
): ProductInCart[] {
  let auxArray: ProductInCart[] = [...productsInCart];

  const productIndex: number = auxArray.findIndex(
    (p, i) =>
      p.id === product.id &&
      JSON.stringify(p.addons) === JSON.stringify(product.addons) &&
      p.observation === product.observation
  );

  if (productIndex > -1)
    if (productsInCart[productIndex].amount - 1 > 0) {
      auxArray[productIndex] = {
        uid: product.uid,
        id: product.id,
        amount: productsInCart[productIndex].amount - 1,
        name: product.name || "",
        observation: product.observation,
        price: product.price,
        addons: product.addons,
        delay: 0,
        can_delay: product.can_delay || false,
        edited_price: product.edited_price || false,
        category_id: product.category_id,
        hide_attributes: product.hide_attributes,
      };
    } else {
      auxArray.splice(productIndex, 1);
    }
  return auxArray;
}

/**
 * 
 * @param productsInCart 
 * @param product 
 * @returns 
 */
export function UpdateProductInCart(
  productsInCart: ProductInCart[],
  product: ProductInCart
): ProductInCart[] {
  let auxArray: ProductInCart[] = [...productsInCart];

  const productIndex: number = auxArray.findIndex(
    (p) => p.id === product.id && p.uid === product.uid
  );

  if (productIndex > -1)
    if (productsInCart[productIndex].amount > 0)
      auxArray[productIndex] = {
        uid: product.uid,
        id: product.id,
        amount: product.amount,
        name: product.name,
        observation: product.observation,
        price: product.price,
        addons: product.addons,
        delay: 0,
        can_delay: product.can_delay || false,
        edited_price: product.edited_price,
        category_id: product.category_id,
        hide_attributes: product.hide_attributes,
      };
    else alert("A");
  else alert("B");

  return auxArray;
}

/**
 * 
 * @param productsInCart 
 * @param product 
 * @returns 
 */
export function DeleteProductFromCart(
  productsInCart: ProductInCart[],
  product: ProductInCart
) {
  let auxArray: ProductInCart[] = [...productsInCart];
  const productIndex: number = auxArray.findIndex(
    (p, i) =>
      p.id === product.id &&
      JSON.stringify(p.addons) === JSON.stringify(product.addons) &&
      p.amount === product.amount &&
      p.observation === product.observation
  );

  if (productIndex > -1) auxArray.splice(productIndex, 1);
  return auxArray;
}

/***************************************************************
 * Menus
 *-------------------------------------------------------------*/

/**
 * 
 * @param productsInCart 
 * @param product 
 * @returns 
 */
export function AddMenuProductToCart(
  productsInCart: MenuProduct[],
  product: MenuProduct
): MenuProduct[] {
  let auxArray: MenuProduct[] = [...productsInCart];
  auxArray.push({
    id: product.id,
    amount: 1,
    name: product.name || "",
    observation: product.observation,
    price: product.price,
    menu_category_id: product.menu_category_id,
    supplement: product.supplement,
    addons: product.addons,
  });

  return auxArray;
}

/// Remove Product from Cart
export function RemoveMenuProductFromCart(
  productsInCart: MenuProduct[],
  product: MenuProduct
): MenuProduct[] {
  let auxArray: MenuProduct[] = [...productsInCart];

  const productIndex: number = auxArray.findIndex((p) => p.id == product.id);

  if (productIndex > -1)
    if (productsInCart[productIndex].amount - 1 > 0) {
      auxArray[productIndex] = {
        id: product.id,
        amount: productsInCart[productIndex].amount - 1,
        name: product.name || "",
        observation: product.observation,
        price: product.price,
        menu_category_id: product.menu_category_id,
        supplement: product.supplement,
      };
    } else {
      auxArray.splice(productIndex, 1);
    }
  return auxArray;
}

export function AddMenuToCart(menusInCart: MenuInCart[], menu: MenuInCart) {
  let auxArray: MenuInCart[] = [...menusInCart];
  auxArray.push(menu);
  return auxArray;
}

export function UpdateMenuInCart(
  menusInCart: MenuInCart[],
  menu: MenuInCart
): MenuInCart[] {
  let auxArray: MenuInCart[] = [...menusInCart];

  const menuIdx: number = auxArray.findIndex((p) => p.uid == menu.uid);

  if (menuIdx > -1)
    if (menusInCart[menuIdx])
      auxArray[menuIdx] = {
        name: menu.name,
        menu_id: menu.menu_id,
        products: menu.products,
        menu_price: menu.menu_price,
        edited_price: menu.edited_price,
        uid: menu.uid,
      };

  return auxArray;
}

export function DeleteMenuFromCart(
  menusInCart: MenuInCart[],
  menu: MenuInCart
) {
  let auxArray: MenuInCart[] = [...menusInCart];
  const menuIndex: number = auxArray.findIndex(
    (p) => p.uid == menu.uid && p.products === menu.products
  );

  if (menuIndex > -1) auxArray.splice(menuIndex, 1);
  return auxArray;
}

export function CalculateBase(total: number) {
  return (10 / 11) * total;
}

export function CalculateIva(total: number) {
  const base = CalculateBase(total);
  return (base * IVA) / 100;
}

export function ToggleAddonsInGroup(
  addonGroup: CategoryAttributes,
  list: CategoryAttributes[]
): CategoryAttributes[] {
  let auxArray: CategoryAttributes[] = [...list];

  const index: number = auxArray.findIndex((a) => a.id == addonGroup.id);

  if (index > -1) auxArray.splice(index, 1);
  else auxArray.push(addonGroup);

  return auxArray;
}
