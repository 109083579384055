export function searchFilter(value: string, search: string) {
  return value
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .includes(
      search
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
}

export function priceFormat(value: string | number | undefined) {
  let str = value ? value.toString().replaceAll(",", ".") : "";
  return str;
}

export function dateFormat(d: Date | string, showHours: boolean) {
  const date = new Date(d);
  let day = date.getDate().toString();
  let month = (1 + date.getMonth()).toString();
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();

  day = day.length === 1 ? "0" + day : day;
  month = month.length === 1 ? "0" + month : month;
  hours = hours.length === 1 ? "0" + hours : hours;
  minutes = minutes.length === 1 ? "0" + minutes : minutes;

  let str = day + "/" + month + "/" + date.getFullYear();

  if (showHours) str += "  " + hours + ":" + minutes;
  return str;
}

export function cropDatetime(d: Date | string) {
  let d_str = d.toString();
  return d_str.substring(0, 19);
}

export function hourFormat(d: Date | string, offset = 1) {
  let date = new Date(d + "");
  date.setHours(date.getHours() + offset);
  let hour = date.getHours().toString();
  let min = date.getMinutes().toString();
  let sec = date.getSeconds().toString();

  if (hour.length === 1) hour = "0" + hour;
  if (min.length === 1) min = "0" + min;
  if (sec.length === 1) sec = "0" + sec;

  let str = hour + ":" + min + ":" + sec;
  return str;
}
