import { initializeApp, FirebaseOptions } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { ReadParameter, makeUid } from "./business";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDkN6uoTEuBWSXDFUbynTtJc5yms985SzY",
  authDomain: "fooddynamic-horeca.firebaseapp.com",
  projectId: "fooddynamic-horeca",
  storageBucket: "fooddynamic-horeca.appspot.com",
  messagingSenderId: "292212426826",
  appId: "1:292212426826:web:1b7f7187af11dcc4c5beb3",
  measurementId: "G-ER61YZDZ48",
  databaseURL:
    "https://fooddynamic-horeca-default-rtdb.europe-west1.firebasedatabase.app/",
};

const env = "PRO";
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export const dbRef = ref(db, `${env}/users/${ReadParameter("slug")}`);
export const updateLastOrder = () => set(dbRef, { lastOrder: makeUid(8) });
