import { TABLE_STATE } from "./States";

// Table states
export const TableColors = [
  {
    STATE: TABLE_STATE.EMPTY,
    COLOR: "#079073",
  },
  {
    STATE: TABLE_STATE.ACTIVE,
    COLOR: "#ffffff99",
  },
  {
    STATE: TABLE_STATE.PENDING,
    COLOR: "#ffde59",
  },
  {
    STATE: TABLE_STATE.BILLED,
    COLOR: "#ff5e5e",
  },
];

// Category hierarchies
export const CommandColors = [
  {
    HIERARCHY_CODE: 0,
    COLOR: "#008004",
    DESCRIPTION: "Bebida",
  },
  {
    HIERARCHY_CODE: 1,
    COLOR: "#1d3da8",
    DESCRIPTION: "Plato",
  },
  {
    HIERARCHY_CODE: 2,
    COLOR: "#C44900",
    DESCRIPTION: "Postre",
  },
];

export const DEFAULT_ICON_COLOR = "#434343";
