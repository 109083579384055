import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  messages = "messages",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Añade un nuevo mensaje en la cola
 * @param text
 * @returns
 */
export const CreateMessage = async (text: string) => {
  //>> Composición de URL
  const url = API_ENDPOINT + RESOURCES.messages;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ text: text }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Update messages as printed
 * @param messages
 * @returns
 */
export const PrintMesssages = async (messages: number[]) => {
  //>> Composición de URL
  const url = API_ENDPOINT + RESOURCES.messages + "/print";

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ messages: messages }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
