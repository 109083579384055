import { SaveProductsCache } from "../../business";
import { Product } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";
import { OverviewProductResponse, ProductResponse } from "../ResponseModel";

const enum RESOURCES {
  products = "products",
  restaurants = "restaurants",
  ingredients = "ingredients",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene la información de un producto a través de su id
 */
export const GetProduct = async (product_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.products;
  const url = API_ENDPOINT + resource + "/" + product_id;

  //>> Respuesta
  type Response = FetchResponseType<ProductResponse>;
  let DefaultResponse: ProductResponse;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Crea un nuevo producto
 */
export const CreateProduct = async (
  product_data: Product,
  allergens_data: string[] = []
) => {
  //>> Composición de URL
  const resource = RESOURCES.products;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<ProductResponse>;
  let DefaultResponse: ProductResponse;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...product_data,
      allergens: allergens_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *  Actualiza la información de un producto
 */
export const UpdateProduct = async (
  product_data: Product,
  allergens_data: string[] = []
) => {
  //>> Composición de URL
  const resource = RESOURCES.products;
  const url = API_ENDPOINT + resource + "/" + product_data.id;

  //>> Respuesta
  type Response = FetchResponseType<ProductResponse>;
  let DefaultResponse: ProductResponse;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...product_data,
      allergens: allergens_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *  Elimina un producto a traves de su id
 */
export const DeleteProduct = async (product_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.products;
  const url = API_ENDPOINT + resource + "/" + product_id;

  //>> Respuesta
  type Response = FetchResponseType<Product>;
  let DefaultResponse: Product;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene los productos para el restaurante actual a través de su session_token
 * @returns Productos
 */
export const GetAllProducts = async () => {
  //>> Composición de URL
  const resource =
    RESOURCES.restaurants + "/" + getSessionToken() + "/" + RESOURCES.products;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<OverviewProductResponse[]>;
  let DefaultResponse: OverviewProductResponse[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: defaultHeaders,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error(
          "Error en la petición al servidor: " + JSON.stringify(res.response)
        );
      }
      SaveProductsCache(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
