import {
  Menu,
  MenuCategory,
  MenuCategoryProduct,
} from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { authHeaders, getSessionToken } from "../auth/AuthServices";
import { MenuProductResponse, MenuResponse } from "../ResponseModel";

const enum RESOURCES {
  menus = "menus",
  menusRestaurants = "menus/restaurants",
  menusCategories = "menus_categories",
  menusCategoriesProduct = "menus_categories_products",
  products = "products",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene los menús de un restaurante a través de su session token
 */
export const GetAllMenus = async () => {
  //>> Composición de URL
  const resource = RESOURCES.menusRestaurants;
  const url = API_ENDPOINT + resource + "/" + getSessionToken();

  //>> Respuesta
  type Response = FetchResponseType<MenuResponse[]>;
  let DefaultResponse: MenuResponse[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene la información de un menú a través de su id
 * @param menu_id
 */
export const GetMenu = async (menu_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.menus;
  const url = API_ENDPOINT + resource + "/" + menu_id;

  //>> Respuesta
  type Response = FetchResponseType<MenuResponse>;
  let DefaultResponse: MenuResponse;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Crea un menú
 * @param menu_data
 * @returns
 */
export const CreateMenu = async (menu_data: Menu) => {
  //>> Composición de URL
  const resource = RESOURCES.menus;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Menu>;
  let DefaultResponse: Menu;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...menu_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Actualiza la informacion básica de un menú
 * @param menu_data
 */
export const UpdateMenu = async (menu_data: Menu) => {
  //>> Composición de URL
  const resource = RESOURCES.menus + "/" + menu_data.id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Menu>;
  let DefaultResponse: Menu;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...menu_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Elimina un menú a través de su id
 * @param menu_id
 * @returns
 */
export const DeleteMenu = async (menu_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.menus;
  const url = API_ENDPOINT + resource + "/" + menu_id;

  //>> Respuesta
  type Response = FetchResponseType<Menu>;
  let DefaultResponse: Menu;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Categorías y productos
 */
export const CreateMenuCategory = async (menu_category_data: MenuCategory) => {
  //>> Composición de URL
  const resource = RESOURCES.menusCategories;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Menu>;
  let DefaultResponse: Menu;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...menu_category_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateMenuCategory = async (
  menu_id: string,
  menu_category_data: any
) => {
  //>> Composición de URL
  const resource = RESOURCES.menusCategories + "/" + menu_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({ menu_categories: menu_category_data }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteMenuCategory = async (menu_category_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.menusCategories;
  const url = API_ENDPOINT + resource + "/" + menu_category_id;

  //>> Respuesta
  type Response = FetchResponseType<Menu>;
  let DefaultResponse: Menu;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateProductsInCategoryMenu = async (
  menu_id: string,
  categories_products_data: any
) => {
  //>> Composición de URL
  const resource = RESOURCES.menusCategoriesProduct + "/" + menu_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ menu_categories: categories_products_data }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene los productos de las categorías de un menú de un menú a través de su id
 * @param menu_id
 */
export const GetMenuCategoriesProducts = async (menu_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.menusCategoriesProduct;
  const url = API_ENDPOINT + resource + "/" + menu_id;

  //>> Respuesta
  type Response = FetchResponseType<MenuCategoryProduct[]>;
  let DefaultResponse: MenuCategoryProduct[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene los productos de un menú a través de su id
 * @param menu_id
 */
export const GetMenuProducts = async (menu_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.menus + "/" + menu_id + "/" + RESOURCES.products;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<MenuProductResponse[]>;
  let DefaultResponse: MenuProductResponse[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateProductsPricesInMenu = async (
  menu_id: string,
  products_data: any
) => {
  //>> Composición de URL
  const resource = RESOURCES.menus + "/" + menu_id + "/products";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ menu_products: products_data }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
