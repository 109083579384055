import { Customer, SaveCustomersCache } from "../../business";
import {
  API_ENDPOINT,
  RESPONSE_CODES,
  METHODS,
  defaultHeaders,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  restaurants = "restaurants",
  customers = "customers",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

export const GetAllCustomers = async () => {
  //>> Composición de URL
  const resource =
    RESOURCES.restaurants + "/" + getSessionToken() + "/" + RESOURCES.customers;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Customer[]>;
  let DefaultResponse: Customer[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      SaveCustomersCache(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetCustomer = async (customer_id: number) => {
  //>> Composición de URL
  const resource = RESOURCES.customers;
  const url = API_ENDPOINT + resource + "/" + customer_id;

  //>> Respuesta
  type Response = FetchResponseType<Customer>;
  let DefaultResponse: Customer;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreateCustomer = async (customer: Customer) => {
  //>> Composición de URL
  const resource = RESOURCES.customers;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ ...customer }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateCustomer = async (customer: Customer) => {
  //>> Composición de URL
  const resource = RESOURCES.customers;
  const url = API_ENDPOINT + resource + "/" + customer.id;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...customer,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteCustomer = async (customer_id: number) => {
  //>> Composición de URL
  const resource = RESOURCES.customers;
  const url = API_ENDPOINT + resource + "/" + customer_id;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
