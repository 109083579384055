import { MutableRefObject } from "react";
import { FormInstance } from "rsuite/esm/Form";

/**
 * Define el tipo para refs de formulario
 */
export type FormType = MutableRefObject<FormInstance<
  Record<string, any>,
  string,
  { [x: string]: string | undefined }
> | null>;

/**
 * Define el tipo para respuestas de servicios
 */
export type FetchResponseType<T> = {
  result_code: number;
  response: T;
};

export enum SCHEDULE_TYPES {
  BUSINESS = "BUSINESS",
  DELIVERY = "DELIVERY",
  TAKEAWAY = "TAKEAWAY",
}

export type SCHEDULE_JSON_HOUR_RANGE = {
  range: string;
};

export enum ORDER_TYPES {
  LOCAL = "LOCAL",
  DELIVERY = "DELIVERY",
  TAKEAWAY = "TAKEAWAY",
}

export enum PAYMENT_METHODS {
  CARD = "CARD",
  CASH = "CASH",
  STRIPE = "STRIPE",
  THIRD_PARTY = "THIRD_PARTY",
}

export function PaymentMethodResolver(v: string): string {
  if (v === "CARD") return "Tarjeta";
  if (v === "CASH") return "Efectivo";
  if (v === "STRIPE") return "App";
  if (v === "BANK") return "Banco";

  return "";
}
