import { Schedule } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  SCHEDULE_TYPES,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  schedules = "schedules",
  restaurants = "restaurants",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

export const GetSchedule = async (scheduleType: SCHEDULE_TYPES) => {
  //>> Composición de URL
  const resource =
    RESOURCES.restaurants +
    "/" +
    getSessionToken() +
    "/" +
    RESOURCES.schedules +
    "/" +
    scheduleType;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Schedule>;
  let DefaultResponse: Schedule;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: defaultHeaders,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateSchedule = async (
  scheduleType: SCHEDULE_TYPES,
  hour_ranges: any
) => {
  //>> Composición de URL
  const resource =
    RESOURCES.restaurants + "/" + getSessionToken() + "/" + RESOURCES.schedules;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...hour_ranges,
      type: scheduleType,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
