import { SaveCategoriesCache } from "../../business";
import {
  AttributeGroup,
  Category,
  RestaurantPrinter,
} from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  categories = "categories",
  categoriesRestaurant = "categories/restaurants",
  addonsGroups = "addons-groups",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene las categorias para el restaurante actual a través de su session_token
 */
export const GetAllCategories = async () => {
  //>> Composición de URL
  const url =
    API_ENDPOINT + RESOURCES.categoriesRestaurant + "/" + getSessionToken();

  //>> Respuesta
  type Response = FetchResponseType<Category[]>;
  let DefaultResponse: Category[];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: defaultHeaders,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      SaveCategoriesCache(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetCategory = async (category_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.categories;
  const url = API_ENDPOINT + resource + "/" + category_id;

  //>> Respuesta
  type Response = FetchResponseType<Category>;
  let DefaultResponse: Category;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreateCategory = async (category: Category) => {
  //>> Composición de URL
  const resource = RESOURCES.categories;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Category>;
  let DefaultResponse: Category;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...category,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateCategory = async (category_data: Category) => {
  //>> Composición de URL
  const resource = RESOURCES.categories;
  const url = API_ENDPOINT + resource + "/" + category_data.id;

  //>> Respuesta
  type Response = FetchResponseType<Category>;
  let DefaultResponse: Category;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...category_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteCategory = async (category_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.categories;
  const url = API_ENDPOINT + resource + "/" + category_id;

  //>> Respuesta
  type Response = FetchResponseType<Boolean>;
  let DefaultResponse: Boolean;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const GetCategoryPrinters = async (categories: string[] | number[]) => {
  //>> Composición de URL
  const resource = RESOURCES.categories + "/printers";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<{
    printers: {
      id: number;
      ip: string;
      port: number;
      categories: number[];
      name: string;
    }[];
    delivery_printers: RestaurantPrinter[];
    takeaway_printers: RestaurantPrinter[];
    custom_printers: RestaurantPrinter[];
    communication_printers: RestaurantPrinter[];
  }>;
  let DefaultResponse: {
    printers: {
      id: number;
      ip: string;
      port: number;
      categories: number[];
      name: string;
    }[];
    delivery_printers: RestaurantPrinter[];
    takeaway_printers: RestaurantPrinter[];
    custom_printers: RestaurantPrinter[];
    communication_printers: RestaurantPrinter[];
  };

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      categories: categories,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      // SetCategoriesPrinters(res.response);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreateCategoryAddon = async (
  category_id?: string | number,
  category_addon_id?: string | number,
  name?: string,
  price?: number
) => {
  //>> Composición de URL
  const resource = RESOURCES.categories + "/addons";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      category_id: category_id,
      category_addon_id: category_addon_id,
      name: name,
      price: price,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateCategoryAddon = async (
  category_addon_id: string | number,
  name: string,
  price: number,
  pay_in_menu: boolean,
  free_product: boolean
) => {
  //>> Composición de URL
  const resource = RESOURCES.categories + "/addons/" + category_addon_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      name: name,
      price: price,
      pay_in_menu: pay_in_menu,
      free_product: free_product,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteCategoryAddon = async (
  category_addon_id: string | number
) => {
  //>> Composición de URL
  const resource = RESOURCES.categories + "/addons/" + category_addon_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreateAddonGroup = async (
  name: string,
  category_id: string | number
) => {
  //>> Composición de URL
  const resource = RESOURCES.addonsGroups;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      name: name,
      category_id: category_id,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateAddonGroup = async (group: AttributeGroup) => {
  //>> Composición de URL
  const resource = RESOURCES.addonsGroups;
  const url = API_ENDPOINT + resource + "/" + group.id;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...group,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteAddonGroup = async (id: number) => {
  //>> Composición de URL
  const resource = RESOURCES.addonsGroups;
  const url = API_ENDPOINT + resource + "/" + id;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
