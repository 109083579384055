import React, { Suspense } from "react";
import { Route } from "react-router";
import { IonRouterOutlet } from "@ionic/react";

const Settings = React.lazy(() => import("../pages/Settings"));
const Caja = React.lazy(() => import("../pages/Caja"));
const Carta = React.lazy(() => import("../pages/Carta/Carta"));
const Categorias = React.lazy(() => import("../pages/Categorias"));
const Cocina = React.lazy(() => import("../pages/Cocina/Cocina"));
const ConfigSala = React.lazy(() => import("../pages/ConfigSala"));
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Menus = React.lazy(() => import("../pages/Menus/Menus"));
const Mesa = React.lazy(() => import("../pages/Mesa"));
const Reservas = React.lazy(() => import("../pages/Reservas"));
const Sala = React.lazy(() => import("../pages/Sala"));
const SignUp = React.lazy(() => import("../pages/Auth/SignUp"));
const Help = React.lazy(() => import("../pages/Help/Help"));
const QRHelper = React.lazy(() => import("../pages/QRHelper/QRHelper"));
const History = React.lazy(() => import("../pages/History/History"));
const Comandero = React.lazy(() => import("../pages/Mesa/Comandero"));
const NewOrder = React.lazy(() => import("../pages/ExternalOrders/NewOrder"));
const ExtOrders = React.lazy(() => import("../pages/ExternalOrders"));
const Print = React.lazy(() => import("../pages/Print/Print"));
const Staff = React.lazy(() => import("../pages/Staff"));

/**
 * Directorio de rutas de la aplicación
 */
const Routes: React.FC = () => {
  return (
    <IonRouterOutlet id="main">
      <Route exact path="/settings">
        <Suspense fallback={<>...</>}>
          <Settings />
        </Suspense>
      </Route>
      <Route exact path="/caja">
        <Suspense fallback={<>...</>}>
          <Caja />
        </Suspense>
      </Route>
      <Route exact path="/history">
        <Suspense fallback={<>...</>}>
          <History />
        </Suspense>
      </Route>
      <Route exact path="/carta">
        <Suspense fallback={<>...</>}>
          <Carta />
        </Suspense>
      </Route>
      <Route exact path="/categorias">
        <Suspense fallback={<>...</>}>
          <Categorias />
        </Suspense>
      </Route>
      <Route exact path="/kds">
        <Suspense fallback={<>...</>}>
          <Cocina />
        </Suspense>
      </Route>
      <Route exact path="/mesa/:tableNumber">
        <Suspense fallback={<>...</>}>
          <Mesa />
        </Suspense>
      </Route>
      <Route exact path="/comandero">
        <Suspense fallback={<>...</>}>
          <Comandero />
        </Suspense>
      </Route>
      <Route exact path="/reservas">
        <Suspense fallback={<>...</>}>
          <Reservas />
        </Suspense>
      </Route>
      <Route exact path="/sala">
        <Suspense fallback={<>...</>}>
          <Sala />
        </Suspense>
      </Route>
      <Route exact path="/spaces">
        <Suspense fallback={<>...</>}>
          <ConfigSala />
        </Suspense>
      </Route>
      <Route exact path="/menus">
        <Suspense fallback={<>...</>}>
          <Menus />
        </Suspense>
      </Route>
      <Route exact path="/signup">
        <Suspense fallback={<>...</>}>
          <SignUp />
        </Suspense>
      </Route>
      <Route exact path="/external/:type">
        <Suspense fallback={<>...</>}>
          <ExtOrders />
        </Suspense>
      </Route>
      <Route exact path="/external/:type/new">
        <Suspense fallback={<>...</>}>
          <NewOrder />
        </Suspense>
      </Route>
      <Route exact path="/print">
        <Suspense fallback={<>...</>}>
          <Print />
        </Suspense>
      </Route>
      <Route exact path="/help">
        <Suspense fallback={<>...</>}>
          <Help />
        </Suspense>
      </Route>
      <Route exact path="/qr-helper">
        <Suspense fallback={<>...</>}>
          <QRHelper />
        </Suspense>
      </Route>
      <Route exact path="/staff">
        <Suspense fallback={<>...</>}>
          <Staff />
        </Suspense>
      </Route>
      <Route exact path="/">
        <Suspense fallback={<>...</>}>
          <Login />
        </Suspense>
      </Route>
    </IonRouterOutlet>
  );
};

export default Routes;
