/* Restaurante */
export enum RESTAURANT_STATE {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DEBT = "DEBT",
}

export enum ORDER_PRODUCT_STATE {
  PENDING = "PENDING",
  PROGRESS = "PROGRESS",
  SERVED = "SERVED",
  REMOVED = "REMOVED",
}

export enum ORDER_STATE {
  PENDING = "PENDING",
  IDLE = "IDLE",
  COMPLETED = "COMPLETED",
}

/* Mesa */
export enum TABLE_STATE {
  EMPTY = "EMPTY",
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  BILLED = "BILLED",
}
