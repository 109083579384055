import { Allergen } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";

const enum RESOURCES {
  allergens = "allergens",
}

/**
 * Obtiene la lista de alérgenos general
 */
export const GetAllergens = async () => {
  //>> Composición de URL
  const resource = RESOURCES.allergens;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Allergen[]>;
  let DefaultResponse: Allergen[];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: defaultHeaders,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
