import { HistoryOrder, Order } from "../../business";
import {
  LoadBusinessData,
  LoadParameters,
  LoadPrintingParameters,
  P,
  ReadParameter,
} from "../../business/ParametersController";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { authHeaders, getSessionToken } from "../auth/AuthServices";
import {
  InfoCajaResponse,
  RestaurantReponse,
  StatsResponse,
} from "../ResponseModel";

const enum RESOURCES {
  restaurants = "restaurants",
  settings = "settings",
  stats = "stats",
  historic = "historic",
  parameters = "settings/parameters",
  toggles = "settings/toggles",
  colors = "settings/colors",
  data = "settings/data",
  printing = "settings/printing",
  reservation = "settings/reservation",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene la información de un restaurante a través del token de la sesión
 * @returns
 */
export const GetRestaurant = async () => {
  //>> Composición de URL
  const resource = RESOURCES.restaurants;
  const url = API_ENDPOINT + resource + "/" + ReadParameter(P.slug);

  //>> Respuesta
  type Response = FetchResponseType<RestaurantReponse>;
  let DefaultResponse: RestaurantReponse;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      LoadBusinessData(
        res.response.name,
        res.response.email,
        res.response.phone,
        res.response.cif,
        res.response.address,
        res.response.legal_name,
        res.response.city,
        res.response.region,
        res.response.zip_code
      );
      LoadParameters(
        res.response.parameters,
        res.response.reservation_parameters?.enable_reservation
      );
      LoadPrintingParameters(res.response.printing_parameters);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Actualiza los parámetros Numéricos de un restaurante
 */
export const UpdateRestaurantNumberParameters = async (
  number_data: Record<string, any>
) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter("slug")}/${
    RESOURCES.parameters
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<RestaurantReponse>;
  let DefaultResponse: RestaurantReponse;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...number_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      LoadBusinessData(
        res.response.name,
        res.response.email,
        res.response.phone,
        res.response.cif,
        res.response.address,
        res.response.legal_name,
        res.response.city,
        res.response.region,
        res.response.zip_code
      );
      LoadParameters(
        res.response.parameters,
        res.response.reservation_parameters?.enable_reservation
      );
      LoadPrintingParameters(res.response.printing_parameters);
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene estadisticas de un restaurante
 */
export const GetRestaurantStats = async (
  from_date: string | null,
  to_date: string | null
) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter(P.slug)}/${
    RESOURCES.stats
  }?`;
  let url = API_ENDPOINT + resource;

  if (from_date != null) url += "&from_date=" + from_date;
  if (to_date != null) url += "&to_date=" + to_date;

  //>> Respuesta
  type Response = FetchResponseType<StatsResponse>;
  let DefaultResponse: StatsResponse;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Retrieves history of orders matching filtering criterias
 * @param from_date
 * @param to_date
 * @param order_id
 * @param table_id
 * @param delivery
 * @param takeaway
 * @param cash
 * @param card
 * @param app
 * @returns
 */
export const GetHistoric = async (
  from_date: string | null,
  to_date: string | null,
  order_id: string,
  table_id: string,
  delivery: boolean,
  takeaway: boolean,
  cash: boolean,
  card: boolean,
  app: boolean,
  credit: boolean
) => {
  //>> Composición de URL
  const resource = RESOURCES.historic;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<HistoryOrder[]>;
  let DefaultResponse: HistoryOrder[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      from_date: from_date,
      to_date: to_date,
      order_id: order_id,
      table_id: table_id,
      delivery: delivery,
      takeaway: takeaway,
      cash: cash,
      card: card,
      app: app,
      credit: credit,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene estadisticas de un restaurante
 */
export const GetInfoCaja = async (
  total: number,
  cash: number,
  card: number,
  app: number,
  save: boolean
) => {
  //>> Composición de URL
  const resource = "info_caja";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<InfoCajaResponse>;
  let DefaultResponse: InfoCajaResponse;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      total: total,
      cash: cash,
      card: card,
      app: app,
      save: save,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Incidencias
 */
export const SendIssue = async (email: string, description: string) => {
  //>> Composición de URL
  const resource = RESOURCES.restaurants + "/issues";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      email: email,
      description: description,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Actualiza los datos de un restaurante
 */
export const UpdateRestaurantData = async (data: Record<string, any>) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter("slug")}/${
    RESOURCES.data
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Actualiza los colores personalizados de un restaurante
 */
export const UpdateRestaurantColors = async (data: Record<string, any>) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter("slug")}/${
    RESOURCES.colors
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Actualiza los valores toggle ON/OFF de un restaurante
 */
export const UpdateRestaurantToggles = async (data: Record<string, any>) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter("slug")}/${
    RESOURCES.toggles
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Actualiza los parámetros de impresion
 */
export const UpdatePrintingParams = async (data: Record<string, any>) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter("slug")}/${
    RESOURCES.printing
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param data
 * @returns
 */
export const UpdateReservationParams = async (data: Record<string, any>) => {
  //>> Composición de URL
  const resource = `${RESOURCES.restaurants}/${ReadParameter("slug")}/${
    RESOURCES.reservation
  }`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error(JSON.stringify(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Incidencias
 */
export const RestoreOrdersData = async () => {
  //>> Composición de URL
  const resource =
    RESOURCES.restaurants + "/" + ReadParameter("slug") + "/orders";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CheckPincode = async (pincode: string) => {
  //>> Composición de URL
  const resource = RESOURCES.restaurants + "/pin";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ pin: pincode }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("RestaurantServices::CheckPincode");
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
