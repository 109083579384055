import { Reservation } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { authHeaders, getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  reservations = "reservations",
  restaurants = "restaurants",
  spaces = "spaces",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene las reservas para el restaurante en una fecha y sala determinadas
 * @param space_id
 * @param date
 * @param daytime
 * @returns
 */
export const GetAllReservations = async (
  space_id: string,
  date: string,
  daytime: string,
  hour: string
) => {
  //>> Composición de URL
  const url =
    API_ENDPOINT + RESOURCES.spaces + `/${space_id}/` + RESOURCES.reservations;

  //>> Respuesta
  type Response = FetchResponseType<{
    reservations: Reservation[];
    tables: {
      id: string;
      grid_number: string;
      table_id: string;
      label: string;
      name: string;
      hour: string;
      people_number: string;
      state: string;
      circle: boolean;
      map_cell: number;
      height: "1" | "2" | "3";
      width: "1" | "2" | "3";
    }[];
    data: {
      capacity: string;
      table_number: string;
      duration: number;
    };
  }>;

  let DefaultResponse: {
    reservations: Reservation[];
    tables: {
      id: string;
      grid_number: string;
      table_id: string;
      label: string;
      name: string;
      hour: string;
      people_number: string;
      state: string;
      circle: boolean;
      map_cell: number;
      height: "1" | "2" | "3";
      width: "1" | "2" | "3";
    }[];
    data: {
      capacity: string;
      table_number: string;
      duration: number;
    };
  };

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ date: date, daytime: daytime, hour: hour }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreateReservation = async (
  reservation_data: Reservation,
  send_email: boolean
) => {
  //>> Composición de URL
  const resource = RESOURCES.reservations;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Reservation>;
  let DefaultResponse: Reservation;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: authHeaders(),
    body: JSON.stringify({
      ...reservation_data,
      send_email: send_email,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateReservation = async (
  reservation_data: Reservation,
  send_email: boolean
) => {
  //>> Composición de URL
  const resource = RESOURCES.reservations;
  const url = API_ENDPOINT + resource + "/" + reservation_data.id;

  //>> Respuesta
  type Response = FetchResponseType<Reservation>;
  let DefaultResponse: Reservation;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      ...reservation_data,
      send_email: send_email,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeleteReservation = async (reservation_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.reservations;
  const url = API_ENDPOINT + resource + "/" + reservation_id;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: authHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param reservation_id
 * @returns
 */
export const SendReservationMail = async (reservation_id: number) => {
  //>> Composición de URL
  const resource = RESOURCES.reservations;
  const url = API_ENDPOINT + resource + `/${reservation_id}/notify`;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const SendMassiveReservationMail = async (
  space_id: string,
  date: string,
  daytime: string,
  hour: string
) => {
  //>> Composición de URL
  const url =
    API_ENDPOINT +
    `${RESOURCES.spaces}/${space_id}/${RESOURCES.reservations}/notify`;

  //>> Respuesta
  type Response = FetchResponseType<null>;

  let DefaultResponse = null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ date: date, daytime: daytime, hour: hour }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateReservationState = async (id: number, state: string) => {
  //>> Composición de URL
  const url = API_ENDPOINT + `${RESOURCES.reservations}/${id}/state`;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse = null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      state: state,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

// /v1/reservations/custom-days
export const UpsertCustomDay = async (date: string, range: any[]) => {
  //>> Composición de URL
  const url = API_ENDPOINT + `${RESOURCES.reservations}/custom-days`;

  //>> Respuesta
  type Response = FetchResponseType<null>;

  let DefaultResponse = null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      date: date,
      range: range,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

// /v1/reservations/custom-day
export const GetCustomDay = async (date: string) => {
  //>> Composición de URL
  const url = API_ENDPOINT + `${RESOURCES.reservations}/custom-day`;

  //>> Respuesta
  type Response = FetchResponseType<string[]>;

  let DefaultResponse = [];

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      date: date,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
