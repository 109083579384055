import { Ingredient } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  ingredients = "ingredients",
  ingredientsRestaurant = "ingredients/restaurants",
  ingredientsProduct = "ingredients/products",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene los productos para el restaurante actual a través de su session_token
 * @returns Productos
 */
export const GetAllIngredients = async () => {
  //>> Composición de URL
  const resource = RESOURCES.ingredientsRestaurant;
  const url = API_ENDPOINT + resource + "/" + getSessionToken();

  //>> Respuesta
  type Response = FetchResponseType<Ingredient[]>;
  const DefaultResponse: Ingredient[] = [];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: defaultHeaders,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Obtiene la información de un ingrediente a través de su id
 */
export const GetIngredient = async (ingredient_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.ingredients;
  const url = API_ENDPOINT + resource + "/" + ingredient_id;

  //>> Respuesta
  type Response = FetchResponseType<Ingredient>;
  let DefaultResponse: Ingredient;

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Crea un nuevo ingrediente
 */
export const CreateIngredient = async (ingredient_data: Ingredient) => {
  //>> Composición de URL
  const resource = RESOURCES.ingredients;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<Ingredient>;
  let DefaultResponse: Ingredient;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...ingredient_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *  Actualiza la información de un ingrediente
 */
export const UpdateIngredient = async (ingredient_data: Ingredient) => {
  //>> Composición de URL
  const resource = RESOURCES.ingredients;
  const url = API_ENDPOINT + resource + "/" + ingredient_data.id;

  //>> Respuesta
  type Response = FetchResponseType<Ingredient>;
  let DefaultResponse: Ingredient;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...ingredient_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *  Elimina un producto a traves de su id
 */
export const DeleteIngredient = async (ingredient_id: string) => {
  //>> Composición de URL
  const resource = RESOURCES.ingredients;
  const url = API_ENDPOINT + resource + "/" + ingredient_id;

  //>> Respuesta
  type Response = FetchResponseType<Ingredient>;
  let DefaultResponse: Ingredient;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Ordena el envío de un pedido de un ingrediente
 */
export const SendIngredientOrder = async (ingredient_data: Record<string, any>) => {
  //>> Composición de URL
  const resource = RESOURCES.ingredients + "/orders";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      ...ingredient_data,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.CREATED) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};