export const enum METHODS {
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
  POST = "POST",
}

export const enum RESPONSE_CODES {
  SUCCESS = 200,
  CREATED = 201,
  FORBIDDEN = 403,
  NO_DATA_FOUND = 404,
  DATA_INTEGRITY_ERROR = 409,
}

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const imageHeaders = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
};
