import { ReadParameter } from "../../business";
import { MenuImage, ProductImage } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  products = "products",
  images = "images",
  restaurants = "restaurants",
}

const imageUploadHeaders = () => {
  return { "FDS-Auth": getSessionToken() };
};

/**
 * Carga las imágenes de un producto existente
 */
export const UploadProductImages = async (product_id: string, image: File) => {
  //>> Composición de URL
  const resource =
    RESOURCES.products + "/" + product_id + "/" + RESOURCES.images;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<ProductImage>;
  let DefaultResponse: ProductImage;

  //>> Data
  const formData = new FormData();
  formData.append("image", image);

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: imageUploadHeaders(),
    body: formData,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Elimina las imágenes de un producto existente
 */
export const RemoveProductImage = async (
  product_id: string,
  image_id: string
) => {
  //>> Composición de URL
  const resource =
    RESOURCES.products +
    "/" +
    product_id +
    "/" +
    RESOURCES.images +
    "/" +
    image_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: imageUploadHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Carga el logotipo de un restaurante
 */
export const UploadRestaurantLogo = async (image: File, cover: boolean) => {
  //>> Composición de URL
  let resource =
    RESOURCES.restaurants + "/" + getSessionToken() + "/" + RESOURCES.images;

  if (cover) resource += "/cover";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  //>> Data
  const formData = new FormData();
  formData.append("image", image);

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: imageUploadHeaders(),
    body: formData,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Elimina el logo de un restaurante existente
 */
export const RemoveRestaurantLogo = async (cover: boolean) => {
  //>> Composición de URL
  const resource =
    RESOURCES.restaurants +
    "/" +
    getSessionToken() +
    "/" +
    RESOURCES.images +
    (cover ? "/cover" : "");
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: imageUploadHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param menu_id
 * @param image
 * @returns
 */
export const UploadMenuImage = async (menu_id: string, image: File) => {
  //>> Composición de URL
  const resource = "menus/" + menu_id + "/" + RESOURCES.images;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<MenuImage>;
  let DefaultResponse: MenuImage;

  //>> Data
  const formData = new FormData();
  formData.append("image", image);

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: imageUploadHeaders(),
    body: formData,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param menu_id
 * @param image_id
 * @returns
 */
export const RemoveMenuImage = async (menu_id: string, image_id: string) => {
  //>> Composición de URL
  const resource = "menus/" + menu_id + "/" + RESOURCES.images + "/" + image_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: imageUploadHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param image
 * @returns
 */
export const UploadPrintableLogo = async (image: File) => {
  //>> Composición de URL
  let resource = `restaurants/${ReadParameter("slug")}/printable_logo`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<{ url: string }>;
  let DefaultResponse: { url: string };

  //>> Data
  const formData = new FormData();
  formData.append("image", image);

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: imageUploadHeaders(),
    body: formData,
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @returns
 */
export const RemovePrintableLogo = async () => {
  //>> Composición de URL
  let resource = `restaurants/${ReadParameter("slug")}/printable_logo`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: imageUploadHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS)
        throw new Error("No se ha podido conectar con el servidor");
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
