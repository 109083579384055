import {
  CategoryPrinter,
  RestaurantPrinter,
  SavePrintersCache,
  SetDefaultPrinters,
} from "../../business";
import {
  API_ENDPOINT,
  RESPONSE_CODES,
  METHODS,
  defaultHeaders,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  printers = "printers",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

export const GetAllPrinters = async () => {
  //>> Composición de URL
  const resource = RESOURCES.printers;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<{
    printers: RestaurantPrinter[];
    delivery_printers: CategoryPrinter[];
    takeaway_printers: CategoryPrinter[];
    custom_printers: CategoryPrinter[];
    communication_printers: CategoryPrinter[];
    table_printers: CategoryPrinter[];
  }>;
  let DefaultResponse: {
    printers: RestaurantPrinter[];
    delivery_printers: CategoryPrinter[];
    takeaway_printers: CategoryPrinter[];
    custom_printers: CategoryPrinter[];
    communication_printers: CategoryPrinter[];
    table_printers: CategoryPrinter[];
  };

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      SavePrintersCache(res.response);

      if (res.response.table_printers && res.response.table_printers.length > 0)
        SetDefaultPrinters(
          res.response.printers.filter(
            (p) =>
              res.response.table_printers.findIndex(
                (tp) => p.id === tp.restaurant_printer_id
              ) > -1
          )
        );

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const CreatePrinter = async (port: string, name: string, ip: string) => {
  //>> Composición de URL
  const resource = RESOURCES.printers;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({ port: port, name: name, ip: ip }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const DeletePrinter = async (printer_id: number) => {
  //>> Composición de URL
  const resource = RESOURCES.printers;
  const url = API_ENDPOINT + resource + "/" + printer_id;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const UpdateCustomPrinters = async (
  delivery_printers: (string | number)[],
  takeaway_printers: (string | number)[],
  custom_printers: (string | number)[],
  communication_printers: (string | number)[],
  table_printers: (string | number)[]
) => {
  //>> Composición de URL
  const resource = "printers/custom";
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null = null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      delivery_printers: delivery_printers,
      takeaway_printers: takeaway_printers,
      custom_printers: custom_printers,
      communication_printers: communication_printers,
      table_printers: table_printers,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
