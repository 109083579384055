import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
  ORDER_PRODUCT_STATE,
} from "../../constants";
import { authHeaders, getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  orders = "orders",
  products = "products",
  ordersProducts = "orders_products",
  print = "print",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 *
 * @param orderProductId
 * @param state
 * @returns
 */
export const UpdateOrderProduct = async (
  orderProductId: string | number,
  state: ORDER_PRODUCT_STATE
) => {
  //>> Composición de URL
  const resource = RESOURCES.ordersProducts;
  const url = API_ENDPOINT + resource + "/" + orderProductId;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      state: state,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 *
 * @param orderId
 * @param orderMenuId
 * @param state
 * @returns
 */
export const UpdateOrderMenu = async (
  orderId: string | number,
  orderMenuId: string | number,
  state: ORDER_PRODUCT_STATE
) => {
  //>> Composición de URL
  const resource = `orders/${orderId}/menus/${orderMenuId}`;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      state: state,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

export const PrintOrderProduct = async (
  orderProductIds: string[] | number[]
) => {
  //>> Composición de URL
  const resource = RESOURCES.ordersProducts + "/" + RESOURCES.print;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<null>;
  let DefaultResponse: null;

  const result: Response = await fetch(url, {
    method: METHODS.POST,
    headers: packedHeaders(),
    body: JSON.stringify({
      order_products: orderProductIds,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Modifica el estado de todos los productos pendientes de un pedido
 */
export const UpdateAllOrderProducts = async (
  order_id: string | number,
  state: ORDER_PRODUCT_STATE
) => {
  //>> Composición de URL
  const resource = RESOURCES.orders + "/" + order_id;
  const url = API_ENDPOINT + resource + "/" + RESOURCES.products;

  //>> Respuesta
  type Response = FetchResponseType<string>;
  let DefaultResponse: string;

  const result: Response = await fetch(url, {
    method: METHODS.PUT,
    headers: authHeaders(),
    body: JSON.stringify({
      state: state,
    }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};
