import { ProductReview } from "../../business/DataModel";
import {
  METHODS,
  defaultHeaders,
  RESPONSE_CODES,
  API_ENDPOINT,
  FetchResponseType,
} from "../../constants";
import { getSessionToken } from "../auth/AuthServices";

const enum RESOURCES {
  products = "products",
  reviews = "reviews",
}

const packedHeaders = () => {
  return { ...defaultHeaders, "FDS-Auth": getSessionToken() };
};

/**
 * Obtiene las reseñas de un producto a través de su id
 */
export const GetProductReviews = async (product_id: string) => {
  //>> Composición de URL
  const resource =
    RESOURCES.products + "/" + product_id + "/" + RESOURCES.reviews;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<ProductReview[]>;
  let DefaultResponse: ProductReview[];

  const result: Response = await fetch(url, {
    method: METHODS.GET,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};

/**
 * Elimina la reseña de un producto a través de su id
 */
export const DeleteProductReview = async (product_id: string, review_id: string) => {
  //>> Composición de URL
  const resource =
    RESOURCES.products +
    "/" +
    product_id +
    "/" +
    RESOURCES.reviews +
    "/" +
    review_id;
  const url = API_ENDPOINT + resource;

  //>> Respuesta
  type Response = FetchResponseType<ProductReview[]>;
  let DefaultResponse: ProductReview[];

  const result: Response = await fetch(url, {
    method: METHODS.DELETE,
    headers: packedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.result_code != RESPONSE_CODES.SUCCESS) {
        throw new Error("No se ha podido conectar con el servidor");
      }
      return res;
    })
    .catch((error) => {
      console.error(error);
      return { result_code: 400, response: DefaultResponse };
    });

  return result;
};